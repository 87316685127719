import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  price: {
    container: (theme: Theme) => css`
      display: flex;
      align-items: center;
      color: ${theme.SUMMARY_SECONDARY_TEXT_COLOR};
      font-weight: 600;
      font-size: 14px;
    `,

    lineThrough: css`
      text-decoration: line-through;
    `,

    discountedPrice: css`
      font-size: 1.1em !important;
    `,

    accent: (theme: Theme) => css`
      span {
        color: ${theme.SUMMARY_TEXT_COLOR};
        filter: none;
        font-weight: 700;
        font-size: 20px;
      }
    `,
  },
}
