import { css, Theme } from '@emotion/react'
import { Breakpoint } from '~/shared/styles'

export const styles = {
  container: css`
    & > div:first-of-type {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `,

  logo: css`
    max-height: 70px;
    max-width: 60%;
    ${Breakpoint.MOBILE} {
      max-width: 45%;
    }
  `,

  button: (theme: Theme) => css`
    background: ${theme.SUMMARY_BUTTON_BACKGROUND_COLOR};
    color: ${theme.SUMMARY_BUTTON_TEXT_COLOR};
    font-size: 16px;
    padding: 16px 30px 16px 20px;

    &:hover {
      box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
    }

    span {
      font-weight: 600;
      margin: 0 16px;
    }
  `,

  rotateIcon: css`
    transform: rotate(180deg);
  `,

  steps: css`
    margin-bottom: 27px;
    display: flex;

    ${Breakpoint.MOBILE} {
      flex-direction: column;
    }
  `,

  info: css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-column-gap: 15px;
    grid-row-gap: 15px;

    ${Breakpoint.MOBILE} {
      grid-template-columns: 1fr;
    }
  `,
}
