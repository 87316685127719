import { css, Theme } from '@emotion/react'
import { Breakpoint } from '~/shared/styles'

export const styles = {
  container: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 22px;

    ${Breakpoint.MOBILE} {
      // email, phone, address
      & > div:nth-of-type(1),
      & > div:nth-of-type(2),
      & > div:nth-of-type(6) {
        grid-column: -1/1;
      }
    }
  `,

  pullInformation: {
    container: {
      base: (theme: Theme) => css`
        position: relative;
        text-align: center;
        background: ${theme.SUMMARY_BUTTON_BACKGROUND_COLOR};

        transition: 1s ease;
        visibility: hidden;
        opacity: 0;
        margin: 0;
        padding: 0;
        height: 0;
      `,

      visible: css`
        visibility: visible;
        opacity: 1;
        height: auto;
        padding: 16px;
        margin-bottom: 26px;
      `,
    },

    closeButton: (theme: Theme) => css`
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      color: ${theme.DARK_0};
    `,

    title: (theme: Theme) => css`
      margin: 10px 0 8px;
      color: ${theme.DARK_0};
      font-weight: 600;
      font-size: 16px;
    `,

    infoTitle: (theme: Theme) => css`
      margin-bottom: 40px;
      color: ${theme.DARK_4};
      font-size: 14px;
      font-weight: 600;
    `,

    enter: (theme: Theme) => css`
      color: ${theme.DARK_4};
      font-size: 14px;
      font-weight: 500;
    `,

    phone: (theme: Theme) => css`
      color: ${theme.DARK_0};
      font-size: 14px;
      font-weight: 600;
    `,

    errorMessage: (theme: Theme) => css`
      color: ${theme.ERROR_1};
      margin-left: 55px;
      text-align: left;
      font-size: 12px;
      font-weight: 500;
    `,
  },

  additionalField: css`
    grid-column: -1/1;
    display: grid;
    grid-row-gap: 18px;
  `,

  address: css`
    display: grid;
    grid-column: -1/1;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;

    ${Breakpoint.MOBILE} {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 28px;
    }
  `,

  checkbox: css`
    margin-top: 15px;
  `,
}
