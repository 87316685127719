import { keyframes } from '@emotion/react'

// spinning animation for loader
export const spinning = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
