import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    text-align: center;
  `,

  title: (theme: Theme) => css`
    margin: 38px 0 8px;
    color: ${theme.DARK_0};
    font-weight: 600;
  `,

  infoTitle: (theme: Theme) => css`
    margin-bottom: 40px;
    color: ${theme.DARK_4};
    font-size: 14px;
    font-weight: 600;
  `,

  button: (theme: Theme) => css`
    background: ${theme.ACCENT_COLOR};
  `,
}
