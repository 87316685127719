import { css, Theme } from '@emotion/react'

export const styles = {
  radio: (theme: Theme) => css`
    position: absolute;
    left: -9999px;

    &:checked + label:after {
      opacity: 1;
    }

    &:checked + label:before {
      border: 2px solid ${theme.ACCENT_COLOR};
    }
  `,

  label: {
    container: css`
      cursor: pointer;
      display: block;
    `,

    text: (theme: Theme) => css`
      padding-left: 30px;
      line-height: 25px;
      position: relative;
      display: inline-block;

      cursor: pointer;

      &:before {
        width: 18px;
        height: 18px;
        margin: auto;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

        background: ${theme.CHECKOUT_BACKGROUND_COLOR};
        border: 2px solid ${theme.DARK_10};
        border-radius: 100%;
      }

      &:after {
        width: 8px;
        height: 8px;
        content: '';
        position: absolute;
        top: 0;
        left: 5px;
        bottom: 0;
        margin: auto;

        background: ${theme.ACCENT_COLOR};
        border-radius: 100%;

        transition: all 0.2s ease;
        opacity: 0;
      }
    `,
  },

  labelTitle: (theme: Theme) => css`
    color: ${theme.DARK_0};
    font-weight: 600;
  `,

  labelPrice: (theme: Theme) => css`
    color: ${theme.DARK_4};
    font-weight: 400;
  `,

  fieldErrorMessage: {
    base: (theme: Theme) => css`
      color: ${theme.ERROR_1};
      font-size: 14px;
      margin-top: 10px;
      font-weight: 500;
      visibility: hidden;
      opacity: 0;
    `,

    visible: css`
      visibility: visible;
      opacity: 1;
    `,
  },
}
