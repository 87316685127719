import { css, Theme } from '@emotion/react'
import { Breakpoint } from '~/shared/styles'

export const styles = {
  popup: (theme: Theme, platform: string) => css`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: 20px;

    overflow-y: scroll;
    overflow-x: hidden;

    background: ${platform === 'bigcommerce' ? theme.DARK_2 : theme.DARK_5};
    backdrop-filter: ${platform === 'bigcommerce' ? `blur(20px)` : `blur(6px)`};

    ${Breakpoint.MOBILE} {
      padding: 20px 0;
    }
  `,

  dialog: css`
    display: flex;
    position: relative;
  `,

  content: (theme: Theme) => css`
    padding: 10px;
    position: relative;
    width: 100%;
    max-width: 560px;

    border-radius: 8px;
    border-top: 5px solid ${theme.ACCENT_COLOR};

    background: ${theme.CHECKOUT_BACKGROUND_COLOR};

    ${Breakpoint.MOBILE} {
      width: auto;
    }
  `,

  header: css`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;

    img {
      cursor: pointer;
    }
  `,

  body: css`
    padding: 0 20px 22px;

    ${Breakpoint.MOBILE} {
      padding: 0 8px;
    }
  `,
}
