import { css } from '@emotion/react'

export const styles = {
  container: css`
    margin: 35px 0 24px;

    display: flex;
    justify-content: center;
  `,

  base: css`
    padding: 12px 21px;
    border-radius: 5px;
  `,

  text: css`
    margin-right: 5px;
  `,

  loader: css`
    margin-right: 10px;
  `,

  contrastIcon: css`
    filter: brightness(0.5);
  `,
}
