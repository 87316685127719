import { css, Theme } from '@emotion/react'

export const styles = {
  button: (theme: Theme) => css`
    min-height: 46px;

    padding: 16px 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    color: ${theme.LIGHT_1};

    background: ${theme.BUTTONS_BACKGROUND_COLOR};
    border-radius: 8px;
    border: none;

    cursor: pointer;
    outline: none;

    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      background: ${theme.DARK_3};
    }
  `,

  loading: (theme: Theme) => css`
    background: ${theme.SUMMARY_BUTTON_BACKGROUND_COLOR};
    color: ${theme.DARK_4};
    cursor: progress;
  `,
}
