import { css } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
  `,

  lineThrough: css`
    text-decoration: line-through;
  `,

  discountedPrice: css`
    margin-left: -20px;
    font-size: 1.2em;
  `,
}
