import { css, Theme } from '@emotion/react'

export const styles = {
  container: (theme: Theme) => css`
    color: ${theme.DARK_4};
    position: absolute;
    z-index: 40;
    right: 20px;
    bottom: 15px;
    font-size: 10px;
  `,
}
