import { css } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;

    background: rgba(255, 61, 0, 0.1);
    border: 1px solid rgba(255, 61, 0, 0.4);
    border-radius: 8px;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #ff3d00;

    grid-column: -1/1;
  `,
}
