import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    word-break: break-word;
  `,

  header: (theme: Theme) => css`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    white-space: nowrap;

    div {
      color: ${theme.DARK_0};
      font-size: 15px;
      font-weight: 600;
      white-space: normal;
    }

    a {
      margin-left: 16px;
      color: ${theme.DARK_BLUE_1};
      border-bottom: 0.6px dashed ${theme.DARK_BLUE_1};

      cursor: pointer;
      user-select: none;

      font-size: 12px;
      font-weight: 500;
    }
  `,

  body: (theme: Theme) => css`
    div {
      line-height: 23px;
      color: ${theme.DARK_4};
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
}
