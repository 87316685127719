import { css, Theme } from '@emotion/react'

export const styles = {
  container: {
    base: (theme: Theme) => css`
      display: flex;
      position: relative;

      input {
        width: 100%;
        padding: 17px 16px;
        z-index: 1;
        flex: 1;

        font-size: 14px;
        font-weight: 500;

        border: 1px solid ${theme.DARK_9};
        border-radius: 8px;
        color: ${theme.DARK_1};
        outline: none;
        background: none;

        // When input focus label goes up
        &:focus + label {
          top: -4px;
          left: 22px;
          z-index: 10;

          color: ${theme.DARK_4};
          font-size: 10px;
          font-weight: 500;
        }
      }

      // When input has value label goes up
      input:not(:placeholder-shown) + label {
        top: -4px;
        left: 22px;
        z-index: 10;

        font-size: 10px;
        font-weight: 500;
      }

      input:focus {
        border: 1px solid ${theme.DARK_1};
      }

      input::placeholder {
        color: transparent;
        font-size: 14px;
        font-weight: 500;
      }

      input:focus::placeholder {
        color: ${theme.DARK_4};
      }

      label {
        position: absolute;
        left: 1rem;
        top: 1rem;
        padding: 0 0.25rem;
        transition: 0.3s;

        color: ${theme.DARK_4};
        font-size: 14px;
        font-weight: 500;

        background-color: ${theme.CHECKOUT_BACKGROUND_COLOR};
      }
    `,

    withIcon: css`
      input {
        text-indent: 28px;
      }
      label {
        left: 2.6rem;
      }
    `,
  },

  error: {
    container: (theme: Theme) => css`
      input {
        border: 1px solid ${theme.ERROR_1};

        &:focus {
          border: 1px solid ${theme.ERROR_1};
        }

        &:focus + label {
          color: ${theme.ERROR_1};
        }
      }

      label {
        color: ${theme.ERROR_1};
      }
    `,

    message: (theme: Theme) => css`
      color: ${theme.ERROR_1};
      position: absolute;
      bottom: -16px;
      font-size: 12px;
      font-weight: 500;
    `,
  },

  success: {
    container: (theme: Theme) => css`
      input {
        border: 1px solid ${theme.SUCCESS_0};

        &:focus {
          border: 1px solid ${theme.SUCCESS_0};
        }

        &:focus + label {
          color: ${theme.SUCCESS_0};
        }
      }

      label {
        color: ${theme.SUCCESS_0};
      }
    `,
  },
}
