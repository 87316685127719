import { Theme } from '@emotion/react'

// TODO: fix cvv in safari
export const getTokenexIframeStyles = (theme: Theme) => {
  const tokenexIframeStyles = {
    base: `appearance:none; box-sizing: border-box; height:52px; width: 100%; padding: 14px 16px; font-size: 14px; font-weight: 500; color: ${theme.DARK_1}; outline: none; background: none; border: 1px solid ${theme.DARK_9}; border-radius: 8px;`,
    focus: `border: 1px solid ${theme.DARK_2};`,
    error: `border: 1px solid ${theme.ERROR_1};`,
    placeholder: `font-size: 14px; font-weight:500; color: ${theme.DARK_4};`,
  }
  return {
    ...tokenexIframeStyles,
    base: `${tokenexIframeStyles.base}; text-indent: 28px;`,
    cvv: {
      ...tokenexIframeStyles,
    },
  }
}
