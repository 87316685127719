import { css } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    align-items: center;

    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
    margin: -6px 0;

    & > span {
      margin-left: 7px;

      font-size: 12px;
      font-weight: 500;
    }
  `,
}
