import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    align-items: center;
    span {
      font-size: 12px;
    }
  `,

  image: css`
    height: 80px;
    width: 80px;
    border-radius: 8px;
    margin-right: 24px;
    object-fit: cover;
  `,

  title: (theme: Theme) => css`
    width: 214px;

    color: ${theme.SUMMARY_TEXT_COLOR};
    font-weight: 700;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,

  info: css`
    display: flex;
    margin-top: 5px;
    span:first-of-type {
      margin-right: 30px;
    }
  `,
}
