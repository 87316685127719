import { Checkout } from '@softcery/qc-apiclient'

/**
 * Provides methods for interaction with Facebook pixel
 */
export class FacebookAnalytics {
  constructor(private fbq: any) {}

  public beginCheckout(checkout: Checkout): void {
    this.fbq(
      'track',
      'InitiateCheckout',
      {
        value: checkout.totalPrice,
        currency: checkout.currency,
        num_items: checkout.products?.length,
        content_ids: checkout.products?.map((item) => item.productId),
        contents: checkout.products?.map((item) => ({
          id: item.productId,
          quantity: item.quantity,
        })),
        content_type: 'product',
      },
      { eventID: checkout.shopCheckoutId },
    )
  }

  public addPaymentInfo(checkout: Checkout): void {
    this.fbq(
      'track',
      'AddPaymentInfo',
      {
        content_ids: checkout.products?.map((item) => item.productId),
        contents: checkout.products?.map((item) => ({
          id: item.productId,
          quantity: item.quantity,
        })),
        content_type: 'product',
        currency: checkout.currency,
        value: checkout.totalPrice,
      },
      { eventID: checkout.shopCheckoutId },
    )
  }

  public purchase(checkout: Checkout): void {
    this.fbq(
      'track',
      'Purchase',
      {
        content_ids: checkout.products?.map((item) => item.productId),
        contents: checkout.products?.map((item) => ({
          id: item.productId,
          quantity: item.quantity,
        })),
        content_type: 'product',
        value: checkout.totalPrice,
        currency: checkout.currency,
      },
      { eventID: checkout.shopCheckoutId },
    )
  }
}
