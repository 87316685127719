import { FacebookAnalytics } from './Facebook'
import { GoogleAnalytics3 } from './GoogleV3'
import { GoogleAnalytics4 } from './GoogleV4'

// getGoogleAnalyticsV3 returns GoogleAnalytics3 if Google Analytics v3 exists
export const getGoogleAnalyticsV3 = (): GoogleAnalytics3 | null => {
  return typeof window.ga === 'function' ? new GoogleAnalytics3(ga) : null
}

// getGoogleAnalyticsV4 returns GoogleAnalytics4 if Google Analytics v4 exists
export const getGoogleAnalyticsV4 = (): GoogleAnalytics4 | null => {
  return typeof window.gtag === 'function' ? new GoogleAnalytics4(gtag) : null
}

// getFacebookAnalytics returns FacebookAnalytics if facebook pixel exists
export const getFacebookAnalytics = (): FacebookAnalytics | null => {
  return typeof window.fbq === 'function' ? new FacebookAnalytics(fbq) : null
}
