import { css } from '@emotion/react'

export const styles = {
  container: {
    base: css`
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;
      user-select: none;
    `,

    visible: css`
      opacity: 1;
      visibility: visible;
    `,
  },
}
