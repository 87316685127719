import { css } from '@emotion/react'

export const styles = {
  container: (platform: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;

    background: ${platform === 'bigcommerce'
      ? `rgba(0, 0, 0, 0.5)`
      : `rgba(0, 0, 0, 0.35)`};
    backdrop-filter: ${platform === 'bigcommerce' ? `blur(20px)` : `blur(6px)`};
  `,

  loader: css`
    height: 50px;
    width: 50px;
  `,
}
