import { css } from '@emotion/react'

export const fonts = css`
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(${require('~/shared/assets/fonts/montserrat-v15-latin-regular.woff2')})
        format('woff2'),
      url(${require('~/shared/assets/fonts/montserrat-v15-latin-regular.woff')})
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(${require('~/shared/assets/fonts/montserrat-v15-latin-500.woff2')})
        format('woff2'),
      url(${require('~/shared/assets/fonts/montserrat-v15-latin-500.woff')})
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(${require('~/shared/assets/fonts/montserrat-v15-latin-600.woff2')})
        format('woff2'),
      url(${require('~/shared/assets/fonts/montserrat-v15-latin-600.woff')})
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(${require('~/shared/assets/fonts/montserrat-v15-latin-700.woff2')})
        format('woff2'),
      url(${require('~/shared/assets/fonts/montserrat-v15-latin-700.woff')})
        format('woff');
    font-display: swap;
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url(${require('~/shared/assets/fonts/montserrat-v15-latin-800.woff2')})
        format('woff2'),
      url(${require('~/shared/assets/fonts/montserrat-v15-latin-800.woff')})
        format('woff');
    font-display: swap;
  }
`
