import { css, Theme } from '@emotion/react'
import { spinning } from '~/shared/styles'

export const styles = {
  container: css`
    text-align: center;
    min-height: 270px;
    display: flex;
  `,

  content: css`
    margin: auto;
  `,

  imageContainer: css`
    height: 100px;
  `,

  loader: css`
    animation: ${spinning} 1.2s linear infinite;
  `,

  title: (theme: Theme) => css`
    margin: 10px 0 8px;
    color: ${theme.DARK_0};
    font-weight: 600;
    font-size: 16px;
  `,

  infoTitle: (theme: Theme) => css`
    margin-bottom: 40px;
    color: ${theme.DARK_4};
    font-size: 14px;
    font-weight: 600;
  `,

  enter: (theme: Theme) => css`
    color: ${theme.DARK_4};
    font-size: 14px;
    font-weight: 500;
  `,

  phone: (theme: Theme) => css`
    color: ${theme.DARK_0};
    font-size: 14px;
    font-weight: 600;
  `,

  errorMessage: (theme: Theme) => css`
    color: ${theme.ERROR_1};
    margin-left: 10px;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  `,

  bottom: {
    container: css`
      margin: 38px 0 33px;
    `,

    text: (theme: Theme) => css`
      color: ${theme.DARK_0};
      font-weight: 500;
      font-size: 14px;
    `,

    button: {
      base: (theme: Theme) => css`
        color: ${theme.ACCENT_COLOR};
        text-decoration: underline;
        font-weight: 700;
        cursor: pointer;
      `,

      disabled: css`
        cursor: initial;
        opacity: 0.7;
      `,
    },
  },

  button: (theme: Theme) => css`
    background: ${theme.ACCENT_COLOR};
  `,
}
