import { css, Theme } from '@emotion/react'

export const styles = {
  container: (theme: Theme) => css`
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      color: ${theme.CHECKOUT_BACKGROUND_COLOR};
      filter: invert(0.9);
      font-weight: 500;
      margin-left: 12px;
    }
  `,

  bar: (theme: Theme) => css`
    width: 250px;
    height: 8px;

    position: relative;
    display: block;

    border-radius: 38px;
    background: ${theme.LIGHT_2};
  `,

  progress: (theme: Theme, width: number) => css`
    position: absolute;
    top: 0;
    width: ${width}%;
    height: 8px;

    border-radius: 38px;
    background: ${width === 100 ? theme.ACCENT_COLOR : theme.DARK_9};
  `,
}
