import { CSSProperties } from 'react'

export const inputStyle = (
  isValid: boolean,
  errorColor: string,
  darkColor: string,
): CSSProperties => ({
  color: !isValid ? errorColor : darkColor,
  width: '45px',
  height: '45px',
  margin: '7.5px',
  borderRadius: '8px',
  border: `1px solid ${!isValid ? errorColor : darkColor}`,
  textAlign: 'center',
  fontSize: '20px',
  fontFamily: 'Montserrat',
  fontWeight: 500,
})
