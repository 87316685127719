import { css, Theme } from '@emotion/react'
import { Breakpoint } from '~/shared/styles'

export const styles = {
  container: css`
    display: flex;
    margin: 0 13px;
    line-height: 19px;
    text-decoration: none;
    cursor: default;

    &:first-of-type {
      margin-left: 0;

      ${Breakpoint.MOBILE} {
        margin: 0;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    ${Breakpoint.MOBILE} {
      margin: 0;
    }
  `,

  accessible: css`
    cursor: pointer;
  `,

  span: {
    base: (theme: Theme) => css`
      margin-right: 5px;

      font-weight: 500;
      font-size: 15px;
      color: ${theme.DARK_4};

      user-select: none;
      cursor: default;

      ${Breakpoint.MOBILE} {
        font-size: 14px;
      }
    `,

    active: (theme: Theme) => css`
      color: ${theme.DARK_0};
      font-weight: 600;
    `,
  },
}
