import { css, Theme } from '@emotion/react'

export const styles = {
  container: {
    base: css`
      height: 52px;
      display: flex;
      position: relative;
    `,

    withIcon: css`
      input {
        text-indent: 28px;
      }
      label {
        left: 2.6rem;
      }
    `,
  },

  error: (theme: Theme) => css`
    color: ${theme.ERROR_1};
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    font-weight: 500;
  `,

  iframeContainer: css`
    height: 52px;
    width: 100%;
  `,

  label: {
    base: (theme: Theme) => css`
      position: absolute;
      left: 1rem;
      top: 1rem;
      padding: 0 0.25rem;
      transition: 0.3s;

      color: ${theme.DARK_4};
      font-size: 14px;
      font-weight: 500;

      background-color: ${theme.CHECKOUT_BACKGROUND_COLOR};

      cursor: text;
    `,

    top: (theme: Theme) => css`
      top: -4px;
      left: 20px;
      z-index: 10;

      color: ${theme.DARK_4};
      font-size: 10px;
      font-weight: 500;

      cursor: default;
    `,

    error: (theme: Theme) => css`
      color: ${theme.ERROR_1};
    `,
  },
}
