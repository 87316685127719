import { css, Theme } from '@emotion/react'
import { Breakpoint } from '~/shared/styles'

export const styles = {
  wrapper: css`
    display: flex;
  `,

  container: {
    base: (theme: Theme) => css`
      width: 352px;
      visibility: hidden;

      padding: 26px 30px 30px;
      border-radius: 5px;
      align-self: center;
      background: ${theme.SUMMARY_BACKGROUND_COLOR};
      z-index: 34;

      ${Breakpoint.TABLET} {
        position: absolute;
        right: 10px;
        top: 150px;
      }

      ${Breakpoint.MOBILE} {
        width: 325px;
      }

      span {
        color: ${theme.SUMMARY_SECONDARY_TEXT_COLOR};
        font-size: 14px;
        font-weight: 600;
      }
    `,

    visible: css`
      visibility: visible;
    `,

    loading: css`
      min-height: 290px;
      display: flex;
      align-items: center;
      justify-content: center;
    `,

    help: {
      container: css`
        margin-top: 45px;
        span {
          font-weight: 300;
          font-size: 15px;
          filter: contrast(0.5);
          margin-left: 20px;
        }
      `,

      call: (theme: Theme) => css`
        display: flex;
        align-items: center;
        margin: 20px 0 11px;
        font-size: 17px;
        color: ${theme.SUMMARY_TEXT_COLOR};

        svg {
          margin-right: 5px;
        }
      `,
    },
  },

  h3: (theme: Theme) => css`
    color: ${theme.SUMMARY_TEXT_COLOR};
    font-weight: 700;
    font-size: 20px;
  `,

  header: css`
    display: flex;
    align-items: center;

    span {
      margin-left: 22px;
      font-weight: 700;
    }
  `,

  priceInfo: css`
    display: grid;
    grid-row-gap: 10px;
    margin: 19px 0 16px;
  `,

  products: css`
    display: grid;
    grid-row-gap: 14px;
    margin-top: 16px;
  `,
}
