import { ChangeEvent } from 'react'

export const clearLetters = (value: string): string => value.replace(/\D+/g, '')

export const normalizeCardNumber = (
  e: ChangeEvent<HTMLInputElement>,
): ChangeEvent<HTMLInputElement> => {
  // const position = e.target.selectionStart
  e.target.value =
    clearLetters(e.target.value)
      .replace(/\s/g, '')
      .match(/.{1,4}/g)
      ?.join(' ')
      .substr(0, 19) || ''
  // e.target.selectionEnd = position
  return e
}

export const formatCVV = (
  e: ChangeEvent<HTMLInputElement>,
): ChangeEvent<HTMLInputElement> => {
  e.target.value = clearLetters(e.target.value).slice(0, 4)
  return e
}

export const formatExpirationDate = (
  e: ChangeEvent<HTMLInputElement>,
): ChangeEvent<HTMLInputElement> => {
  e.target.value = clearLetters(e.target.value).slice(0, 2)
  return e
}
