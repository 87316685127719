import * as Yup from 'yup'
import 'yup-phone'
import { CANADA_PROVINCES, COUNTRIES, USA_STATES } from './constants'

interface Error {
  required: string
  pattern?: string
}

// errors for Delivery screen fields
const ERRORS: Record<string, Error> = {
  email: {
    required: 'Email is required',
    pattern: 'Please enter a valid email',
  },
  phone: {
    required: 'Phone number is required ',
  },
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  address1: {
    required: 'Address 1 is required',
  },
  country: {
    required: 'Country is required',
  },
  city: {
    required: 'City is required',
  },
  zip: {
    required: 'Zip Code is required',
  },
}

// validation for Delivery screen fields
export const validationSchema = (country: string) =>
  Yup.object({
    email: Yup.string().email(ERRORS.email.pattern).required(ERRORS.email.required),
    phone: Yup.string()
      .phone('IN', false, 'Invalid phone number')
      .required(ERRORS.phone.required),
    firstName: Yup.string().required(ERRORS.firstName.required),
    lastName: Yup.string().required(ERRORS.lastName.required),
    address1: Yup.string().required(ERRORS.address1.required),
    country: Yup.string()
      .required(ERRORS.country.required)
      .test('test-country', 'Not in list', (value) => {
        return COUNTRIES.some((country) => country.name === value)
      }),
    city: Yup.string().required(ERRORS.city.required),
    province: Yup.lazy(() => {
      switch (country) {
        case 'United States':
          return Yup.string()
            .required('State is required')
            .test('test-state', 'Not in list', (value) => {
              return USA_STATES.some((state) => state.name === value)
            })
        case 'Canada':
          return Yup.string()
            .required('Province is required')
            .test('test-province', 'Not in list', (value) => {
              return CANADA_PROVINCES.some((province) =>
                province.name.split(',').some((name) => name === value),
              )
            })
        default:
          return Yup.string().required('District is required')
      }
    }),
    zip: Yup.string().required(ERRORS.zip.required),
  })
