import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `,

  secured: (theme: Theme) => css`
    margin-top: 22px;
    display: flex;
    align-items: center;

    font-size: 12px;
    font-weight: 700;
    color: ${theme.DARK_4};

    span {
      margin-left: 5px;
    }
  `,
}
