import { css, Theme } from '@emotion/react'
import { Breakpoint } from '~/shared/styles'

export const styles = {
  header: css`
    display: flex;
    justify-content: space-between;

    div:first-of-type {
      margin-right: 10px;
    }
  `,

  formFields: css`
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 22px;
  `,

  title: {
    container: css`
      display: flex;
      align-items: center;
    `,

    text: (theme: Theme) => css`
      margin-left: 12px;
      color: ${theme.DARK_0};
      font-size: 19px;
      font-weight: 600;
      transition: all 0.3s ease;
    `,
  },

  coupon: {
    container: css`
      display: grid;
      grid-template-columns: 4fr 1fr;
      grid-gap: 20px;
    `,

    buttonContainer: css`
      margin: 0;
    `,

    button: {
      base: css`
        margin: 0;
      `,
    },
  },

  paymentContainer: {
    base: css`
      display: grid;
      grid-row-gap: 22px;
      visibility: hidden;
      opacity: 0;
      height: 0;
      transition: all 0.3s ease;
    `,

    visible: css`
      visibility: visible;
      opacity: 1;
      height: auto;
    `,
  },

  cardInfo: css`
    display: grid;
    grid-template-columns: 4fr 1fr 1fr 1fr;
    grid-row-gap: 28px;
    grid-column-gap: 12px;

    ${Breakpoint.MOBILE} {
      grid-template-columns: 1fr 1fr 1fr;

      div:first-of-type {
        grid-column: -1/1;
      }
    }
  `,

  cardIcon: css`
    height: 100%;
    width: 23px;
    position: absolute;
    top: 0;
    left: 16px;
  `,

  savedCard: {
    container: (theme: Theme) => css`
      border: 1px solid ${theme.DARK_9};
      border-radius: 8px;
      padding: 12px 24px 5px;
      position: relative;
    `,

    label: (theme: Theme) => css`
      background-color: ${theme.CHECKOUT_BACKGROUND_COLOR};
      position: absolute;
      top: -6px;
      left: 24px;
      z-index: 10;

      color: ${theme.DARK_4};
      font-size: 10px;
      font-weight: 500;
    `,

    editButton: css`
      cursor: pointer;
      position: absolute;
      right: 16px;
    `,

    card: {
      container: (theme: Theme) => css`
        display: flex;
        align-items: center;
        margin-bottom: 4px;
        color: ${theme.DARK_1};
        font-size: 14px;
        font-weight: 500;
      `,

      icon: css`
        margin-right: 10px;
        width: 20px;
      `,

      holder: (theme: Theme) => css`
        margin-bottom: 2px;
        color: ${theme.DARK_1};
        font-size: 14px;
        font-weight: 500;
      `,

      expires: (theme: Theme) => css`
        color: ${theme.DARK_4};
        font-size: 14px;
        font-weight: 500;
      `,
    },
  },

  checkboxesWrapper: css`
    display: flex;
    flex-direction: column;
  `,

  agreementCheckbox: css`
    margin: 23px 0 10px 0;
  `,

  marketingCheckbox: css`
    margin-bottom: 10px;
  `,

  button: (theme: Theme) => css`
    background: ${theme.ACCENT_COLOR};
    &:hover {
      background: ${theme.BUTTONS_BACKGROUND_COLOR};
    }
  `,

  dNone: css`
    display: none;
  `,
}
