import { css, Theme } from '@emotion/react'

export const styles = {
  title: {
    base: (theme: Theme) => css`
      color: ${theme.DARK_0};
      font-size: 19px;
      font-weight: 600;
      transition: all 0.3s ease;
    `,

    error: (theme: Theme) => css`
      color: ${theme.ERROR_1};
    `,
  },
}
