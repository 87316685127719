import { Product } from '@softcery/qc-apiclient/index'

export const getCheckoutItemsFromProducts = (products?: Product[]): object[] => {
  if (!products) return []
  return products.map((product) => ({
    item_name: product.title,
    item_id: product.productId,
    price: product.price,
    quantity: product.quantity,
    discount: Number(product.price) - Number(product.discountedPrice),
  }))
}
