export const BreakpointWidth = {
  MOBILE: 576,
  TABLET: 992,
  PC: 1200,
}

export const Breakpoint = {
  MOBILE: `@media (max-width: ${BreakpointWidth.MOBILE - 1}px)`,
  TABLET: `@media (max-width: ${BreakpointWidth.TABLET - 1}px)`,
  PC: `@media (max-width: ${BreakpointWidth.PC - 1}px)`,
}
