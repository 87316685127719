import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    position: relative;
  `,

  dropdown: {
    container: (theme: Theme) => css`
      max-height: 130px;
      overflow-y: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 55px;
      z-index: 18;

      background: ${theme.CHECKOUT_BACKGROUND_COLOR};
      border: 1px solid ${theme.DARK_9};
      border-radius: 8px;
    `,

    item: (theme: Theme) => css`
      padding: 13.5px 16px;
      color: ${theme.DARK_1};
      cursor: pointer;
      user-select: none;
      transition: all 0.3s ease;
      background: ${theme.CHECKOUT_BACKGROUND_COLOR};

      &:hover {
        background: ${theme.SUMMARY_BUTTON_BACKGROUND_COLOR};
        color: ${theme.DROPDOWN_HIGHLIGHT_TEXT_COLOR};
      }
    `,
  },

  loader: css`
    display: block;
    height: 30%;
    margin: 10px auto;
  `,
}
