import { css, Theme } from '@emotion/react'

export const styles = {
  container: css`
    display: inline-flex;
    align-items: center;
  `,

  checkbox: {
    base: (theme: Theme) => css`
      width: 18px;
      height: 18px;

      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      background: ${theme.CHECKOUT_BACKGROUND_COLOR};
      border: 2px solid ${theme.DARK_4};
      border-radius: 2px;
      user-select: none;
    `,

    checked: (theme: Theme) => css`
      background: ${theme.ACCENT_COLOR};
      border: none;
    `,
  },

  label: (theme: Theme) => css`
    margin-left: 10px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.DARK_1};
    user-select: none;

    a {
      color: ${theme.DARK_BLUE_1};

      text-decoration: none;
    }
  `,

  icon: css`
    display: block;
    width: 11px;
    height: 100%;

    filter: brightness(0) invert(1);
  `,
}
